// This file is automatically compiled by Vite, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
console.log('Vite ⚡️ Rails');

import '@hotwired/turbo-rails';
import '../controllers';
import * as Turbo from '@hotwired/turbo';
Turbo.session.drive = false; // Enable explicitely with `data-turbo="true"` on links.

import { StreamActions } from '@hotwired/turbo';
StreamActions.remove_later = function () {
  setTimeout(() => {
    this.targetElements.forEach((element) => element.remove());
  }, this.getAttribute('after'));
};

import * as ActiveStorage from '@rails/activestorage';
ActiveStorage.start();

const channels = import.meta.glob('./**/*_channel.js', { eager: true });

import { publish, subscribe, unsubscribe } from 'utils/pubsub';
if (typeof window !== 'undefined') {
  window.publish = publish;
  window.subscribe = subscribe;
  window.unsubscribe = unsubscribe;
}

import Alpine from 'alpinejs';

if (typeof window !== 'undefined') {
  window.Alpine = Alpine;
}
Alpine.start();

if (typeof window !== 'undefined') {
  import('flag-icons/css/flag-icons.css');
}

import 'flowbite';
import 'flowbite/dist/flowbite.turbo.js';
import { initFlowbite } from 'flowbite';
// import 'flowbite/dist/datepicker.js';
// import 'flowbite/dist/datepicker.turbo.js';

// for some reason, the flowbite.turbo.js doesn't work, so we have to manually call the initFlowbite function
document.addEventListener('turbo:after-stream-render', () => {
  initFlowbite();
});

Turbo.config.forms.confirm = (message) => {
  // Drop once https://github.com/hotwired/turbo/pull/856 gets merged
  let submitter = document.querySelector(
    '*[data-turbo-confirm="' + message + '"]',
  );

  let dialog = document.getElementById('turbo-confirm');
  dialog.querySelector('p').textContent = message;
  dialog.querySelector('h3').textContent =
    submitter.getAttribute('data-turbo-dialog-title') || 'Confirm';

  dialog.querySelector('button[value=cancel]').textContent =
    submitter.getAttribute('data-turbo-dialog-cancel-text') || 'Cancel';
  dialog.querySelector('button[value=confirm]').textContent =
    submitter.getAttribute('data-turbo-dialog-confirm-text') || 'Yes';

  dialog.showModal();

  return new Promise((resolve, reject) => {
    dialog.addEventListener(
      'close',
      () => {
        resolve(dialog.returnValue == 'confirm');
      },
      { once: true },
    );
  });
};
